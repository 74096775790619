@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./plyr";

@font-face {
  font-family: "LaNord";
  src: url("../public/fonts/LaNord-Light.woff") format("woff"),
    url("../public/fonts/LaNord-Light.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LaNord";
  src: url("../public/fonts/LaNord-SemiLight.woff") format("woff"),
    url("../public/fonts/LaNord-SemiLight.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ZanadFont";
  // src: url('../public/fonts/zanad-font.woff') format('woff'), url('../public/fonts/zanad-font.woff2') format('woff2');
  src: url("../public/fonts/ZanadSymbols-SemiLight_2.3.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  font-family: "LaNord";
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  overflow-x: hidden;
  background-color: #fff;

  &.loading {
    cursor: wait;
  }
}

::selection {
  color: #fff;
  background: #000;
}
a {
  color: inherit;
  text-decoration: none;
}

.offscreen-title {
  position: fixed;
  top: -99999px;
  left: -99999px;
}

.fluid-type {
  //font-size: clamp(5rem, 1vw - 3rem, 80rem);
  font-family: "LaNord";
  font-weight: 100;
  font-size: calc(36px + (100 - 36) * ((100vw - 300px) / (1600 - 300)));
  line-height: 0.95;
  letter-spacing: -0.15rem;
}

.fluid-type-medium {
  font-family: "LaNord";
  font-weight: 100;
  font-size: calc(30px + (55 - 30) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1;
  letter-spacing: -0.025rem;
}

.fluid-type-small-v2 {
  font-family: "LaNord";
  font-weight: 100;
  font-size: calc(22px + (70 - 22) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1;
  letter-spacing: 0.02rem;
}

.fluid-type-small {
  font-family: "LaNord";
  font-weight: 300;
  font-size: calc(22px + (60 - 22) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1.1;
  letter-spacing: 0.02rem;
}

.fluid-type-extrasmall {
  font-family: "LaNord";
  font-weight: 300;
  font-size: calc(22px + (38 - 22) * ((100vw - 300px) / (1600 - 300)));
  line-height: 1.4;
  letter-spacing: 0.02rem;
}

.glyph-icon {
  font-family: "ZanadFont";
}

.nav-icon {
  width: 21.5px;
  height: 18px;
  top: 6px;
  left: 0;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 22.5px;
    top: -9px;
    left: 7px;
  }
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    @media (min-width: 768px) {
      height: 3px;
    }
    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 6px;
      transform-origin: left center;
      @media (min-width: 768px) {
        top: 8px;
      }
    }
    &:nth-child(3) {
      top: 12px;
      transform-origin: left center;
      @media (min-width: 768px) {
        top: 16px;
      }
    }
  }
  &.opened {
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: -1px;
        left: 3px;
        @media (min-width: 768px) {
          top: 0px;
          left: 3px;
        }
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 14px;
        left: 3px;
        @media (min-width: 768px) {
          top: 16px;
          left: 3px;
        }
      }
    }
  }
}

.animated-border {
  display: inline-block;
  &:after {
    display: block;
    content: "";
    transform: scaleX(0) translateY(-4px);
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: 100% 50%;

    @media screen and (min-width: 768px) {
      transform: scaleX(0) translateY(-3px);
    }
  }
  &:hover {
    &:after {
      transform: scaleX(1) translateY(-4px);
      transform-origin: 0% 50%;

      @media screen and (min-width: 768px) {
        transform: scaleX(1) translateY(-3px);
      }
    }
  }
  &.light-border {
    &:after {
      border-bottom: solid 2px #fff;

      @media screen and (min-width: 768px) {
        border-bottom: solid 3px #fff;
      }
    }
  }
  &.dark-border {
    &:after {
      border-bottom: solid 3px #000;
    }
    &.thin-border {
      &:after {
        border-bottom: solid 2px #000;
      }
    }
  }
}

input:checked + label {
  &.animated-border {
    &:after {
      transform: scaleX(1) translateY(-3px);
      transform-origin: 0% 50%;
    }
  }
}

.image-container {
  position: relative;
  width: 100%;
  background-color: #f5f5f5;

  & > span {
    position: unset !important;
    height: 100% !important;
  }

  img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

// .glyph-image-container {
//   img {
//     // width: 6.5rem !important;
//     display: inline;
//     position: relative;
//     width: calc(40px + (110 - 40) * ((100vw - 300px) / (1600 - 300)));
//     line-height: 0.6;
//     transform: translateY(
//       calc(-0.3rem + (1 - 2) * ((100vw - 300px) / (1600 - 300)))
//     );
//   }
// }

.glyph-image-container {
  img {
    display: inline;
    position: relative;
    height: calc(40px + (110 - 40) * ((100vw - 300px) / (1600 - 300)));
    width: auto;
    line-height: 0.6;
    transform: translateY(
      calc(-0.2rem + (1 - 2) * ((100vw - 300px) / (1600 - 300)))
    );
  }

  @media (min-width: 1024px) {
    img {
      transform: translateY(
        calc(-0.5rem + (1 - 2) * ((100vw - 300px) / (1600 - 300)))
      );
    }
  }
}

.close-overlay {
  cursor: url("/assets/icons/close-overlay.svg") 25 25, auto;
}

#mc-embedded-subscribe {
  text-indent: -9999px;
  background-image: url("/assets/icons/submit-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.projects-featured {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  > div {
    grid-column: span 12;
  }
  @media (min-width: 768px) {
    > div:nth-child(8n + 1) {
      grid-column: span 8;
    }

    > div:nth-child(8n + 2) {
      grid-column: span 4;
    }

    > div:nth-child(8n + 3) {
      grid-column: span 4;
    }

    > div:nth-child(8n + 4) {
      grid-column: span 8;
    }

    > div:nth-child(8n + 5) {
      grid-column: span 8;
    }

    > div:nth-child(8n + 6) {
      grid-column: span 12;
    }

    > div:nth-child(8n + 7) {
      grid-column: span 4;
    }

    > div:nth-child(8n + 8) {
      grid-column: span 8;
    }
  }
}

.screensaver-sticker {
  position: absolute;
  width: 100px;

  &.sticker-0,
  &.sticker-1,
  &.sticker-2,
  &.sticker-3 {
    width: 80px;
  }

  &.sticker-16,
  &.sticker-17,
  &.sticker-18,
  &.sticker-19 {
    width: 110px;
  }

  @media (min-width: 768px) {
    width: 140px;

    &.sticker-0,
    &.sticker-1,
    &.sticker-2,
    &.sticker-3 {
      width: 120px;
    }

    &.sticker-16,
    &.sticker-17,
    &.sticker-18,
    &.sticker-19 {
      width: 150px;
    }
  }
  @media (min-width: 1140px) {
    width: 180px;

    &.sticker-0,
    &.sticker-1,
    &.sticker-2,
    &.sticker-3 {
      width: 160px;
    }

    &.sticker-16,
    &.sticker-17,
    &.sticker-18,
    &.sticker-19 {
      width: 200px;
    }
  }
}

.btn-ticker {
  height: calc(28px + (63 - 28) * ((100vw - 300px) / (1600 - 300)));
  width: calc(74px + (180 - 74) * ((100vw - 300px) / (1600 - 300)));
  span {
    height: calc(28px + (63 - 28) * ((100vw - 300px) / (1600 - 300)));
  }
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.btn-ticker-small {
  height: calc(32px + (55 - 32) * ((100vw - 300px) / (1600 - 300)));
  width: calc(105px + (135 - 74) * ((100vw - 300px) / (1600 - 300)));
  span {
    height: calc(32px + (55 - 32) * ((100vw - 300px) / (1600 - 300)));
  }
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.btn-ticker-full {
  height: calc(50px + (120 - 34) * ((100vw - 300px) / (1600 - 300)));
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  width: min(90%, 450px);
  // width: 100%;
  margin-inline: auto;

  @media screen and (min-width: 414px) {
    margin-inline: 0;
  }

  @media screen and (min-width: 1024px) {
    height: calc(28px + (120 - 28) * ((100vw - 300px) / (1600 - 300)));
    width: 100%;
  }

  span {
    height: calc(22px + (63 - 22) * ((100vw - 300px) / (1600 - 300)));

    @media screen and (min-width: 1024px) {
      height: calc(28px + (63 - 28) * ((100vw - 300px) / (1600 - 300)));
    }
  }
}

.btn-ticker-full-v2 {
  span {
    height: calc(22px + (63 - 22) * ((100vw - 300px) / (1600 - 300)));

    @media screen and (min-width: 1024px) {
      height: calc(28px + (63 - 28) * ((100vw - 300px) / (1600 - 300)));
    }
  }
}

.btn-ticker-inner {
  display: block;
  overflow: hidden;

  span {
    position: relative;
    display: inline-block;
    padding: 0 14px;
    animation: btn-marquee 4s linear infinite;
    animation-play-state: running;

    &.speed-25 {
      animation: btn-marquee 2.5s linear infinite;
      padding: 0 10px;

      &:after {
        padding: 0 11px;
      }
    }

    &:hover {
      animation-play-state: paused;
    }

    &:after {
      content: attr(data-text);
      position: absolute;
      left: 100%;
      padding: 0 15px;
    }
  }
}

.btn-ticker-inner-v2 {
  display: block;
  overflow: hidden;
  span {
    position: relative;
    display: inline-block;
    padding: 0 14px;
    animation: btn-marquee 20s linear infinite;
    animation-play-state: running;
    &:hover {
      animation-play-state: paused;
    }
    &:after {
      content: attr(data-text) attr(data-text);
      position: absolute;
      left: 100%;
      padding: 0px;
    }
  }
}

.btn-ticker-inner-v3 {
  display: block;
  overflow: hidden;

  span {
    font-family: "LaNord";
    font-size: 20px;
    margin-top: 2px;
    position: relative;
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 14px;
    padding-left: 0px;
    animation: btn-marquee 1.5s infinite linear;
    animation-play-state: running;

    &:after {
      content: attr(data-text) attr(data-text);
      position: absolute;
      left: 100%;
    }
  }

  @media (min-width: 768px) {
    span {
      font-size: 24px;
    }
  }
}

@keyframes btn-marquee {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.card {
  .marquee {
    min-width: auto !important;
  }
  // img {
  //     transform: scale(1);
  //     transition: transform .2s cubic-bezier(0.78,0,0.24,1);
  // }
  // a:hover {
  //     img {
  //         transform: scale(1.02);
  //     }
  // }
}

.animated-box {
  //display: block;
}

// .project-card {
//     a {
//         height: 100%;
//         display: block;
//     }
// }

#project-details {
  grid-template-rows: auto 1fr;
}

.CookieConsent {
  @apply bg-white fixed z-20 bottom-2 768:bottom-5 px-4 py-2 border-2 border-black;
  bottom: 0.5rem !important;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 1rem);
  @media (min-width: 768px) {
    width: max-content;
    bottom: 1.25rem !important;
  }
}

.studio-slideshow {
  .swiper-slide {
    max-width: 90vw;
  }
}

.team-slideshow {
  // img {
  //     transform: scale(1);
  //     transition: all .25s;
  // }
  .swiper-wrapper {
    padding-bottom: 80px;
  }

  .swiper-slide {
    max-width: 80vw;
    @media (min-width: 640px) {
      max-width: 40vw;
    }
    cursor: pointer;
    // &:hover {
    //     img {
    //         transform: scale(1.05);
    //     }
    // }
  }
  .navigation-wrapper {
    top: calc(50% - 95px);
  }
}

.carousel {
  .swiper-slide {
    @apply ml-2 768:ml-5;
    &:last-child {
      @apply mr-2 768:mr-5;
    }
  }
}

.slideshow-control {
  transform: translateY(calc(-50% + 25px));

  svg {
    width: 30px;
  }
  @media (min-width: 768px) {
    svg {
      width: 50px;
    }
  }
  circle,
  path {
    transition: all 0.3s;
  }
  svg:hover {
    circle {
      fill: #000;
    }
    path {
      fill: #fff;
    }
  }
}

.text-content {
  ul {
    padding-left: 1.2rem;
  }
  li {
    list-style-type: disc;
  }
}

.text-contacts {
  a {
    border-bottom: 1px solid transparent;
    transition: border 0.25s ease-in-out;
    &:hover {
      border-bottom: 1px solid #000;
    }
  }
}
#members-modal {
  transition-timing-function: cubic-bezier(0.78, 0, 0.24, 1);
  width: 100%;
  max-width: 760px;

  @media (min-width: 768px) {
    width: min(70vw, 760px);
  }

  h3 {
    font-size: clamp(40px, 6vw, 90px);
  }
  #member-description,
  h4 {
    font-size: clamp(16px, 3vw, 22px);
  }
}

.optical-align {
  &.letter-i,
  &.letter-r {
    margin-left: -0.53vw;
  }
  &.letter-b,
  &.letter-d,
  &.letter-f,
  &.letter-p,
  &.letter-u {
    margin-left: -0.5vw;
  }
  &.letter-c,
  &.letter-s,
  &.letter-o {
    margin-left: -0.33vw;
  }
  &.letter-z {
    margin-left: -0.275vw;
  }
}

.plyr__tooltip {
  background-color: #fff !important;
  color: #000 !important;
  &:before {
    background-color: #fff !important;
  }
}

.blink {
  animation: blinker 0.125s linear infinite;
  animation-iteration-count: 1;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.content-with-glyphs {
  strong {
    font-family: "ZanadFont";
  }
}

body.loading {
  .logo {
    transition-delay: 400ms;
  }
}

.team-member-slide {
  .team-member-slide-tooltip {
    transition: top ease-out 75ms, left ease-out 75ms, opacity ease 250ms;
    z-index: 99;
    width: max-content;
    max-width: calc(4 / 6 * 100%);
  }

  @media screen and (hover: hover) {
    &:hover .team-member-slide-tooltip {
      opacity: 1;
    }
  }
}

#section-eight.overlap {
  margin-top: -27%;
}

#section-four .overlap {
  margin-bottom: -75%;
  z-index: 3;
  @media (min-width: 1920px) {
    margin-bottom: -80% !important;
  }
  @media (max-width: 640px) {
    margin-bottom: -120% !important;
  }
}

.floating-sketch {
  mix-blend-mode: multiply;
  z-index: 4;
  filter: brightness(1.1);
}

.paragraph_style p {
  font-size: 2vw;
  @media (min-width: 640px) {
    font-size: 1rem;
  }
}

.media_caption p {
  font-size: 2.7vw;
  @media (min-width: 640px) {
    font-size: 1rem;
  }
}

.paragraph_style {
  font-size: 1rem;
  @media (max-width: 640px) {
    font-size: 0.9rem;
    line-height: 0.6rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #000 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.plyr__control[data-plyr="mute"] svg {
  // display: block !important;
}

.project-info {
  line-height: 1;
}

.plyr {
  min-width: unset !important;
}
